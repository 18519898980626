interface StageConfig {
    readonly DEVICE_ENDPOINT: string;
    readonly UI_ENDPOINT: string;
}

export const CONFIGS = (): { [stage: string]: { [region: string]: StageConfig } } => {
    const deviceSubDomain = navigator.userAgent.match(/Windows/i) !== null ? "alb-device" : "device";
    return {
        "alpha": {
            "us-west-2": {
                DEVICE_ENDPOINT: `https://alpha.${deviceSubDomain}.1p-idp.ihm.amazon.dev`,
                UI_ENDPOINT: "https://alpha.ui.1p-idp.ihm.amazon.dev"
            }
        },
        "beta": {
            "us-west-2": {
                DEVICE_ENDPOINT: `https://beta.${deviceSubDomain}.1p-idp.ihm.amazon.dev`,
                UI_ENDPOINT: "https://beta.ui.1p-idp.ihm.amazon.dev"
            }
        },
        "gamma": {
            "us-west-2": {
                DEVICE_ENDPOINT: `https://gamma.us-west-2.${deviceSubDomain}.1p-idp.ihm.amazon.dev`,
                UI_ENDPOINT: "https://gamma.us-west-2.ui.1p-idp.ihm.amazon.dev"
            }
        },
        "prod": {
            "us-west-2": {
                DEVICE_ENDPOINT: "https://prod.us-west-2.device.1p-idp.ihm.amazon.dev",
                UI_ENDPOINT: "https://prod.us-west-2.ui.1p-idp.ihm.amazon.dev"
            },
            "us-east-1": {
                DEVICE_ENDPOINT: "https://prod.us-east-1.device.1p-idp.ihm.amazon.dev",
                UI_ENDPOINT: "https://prod.us-east-1.ui.1p-idp.ihm.amazon.dev"
            },
            "eu-west-1": {
                DEVICE_ENDPOINT: "https://prod.eu-west-1.device.1p-idp.ihm.amazon.dev",
                UI_ENDPOINT: "https://prod.eu-west-1.ui.1p-idp.ihm.amazon.dev"
            },
            "ap-southeast-1": {
                DEVICE_ENDPOINT: "https://prod.ap-southeast-1.device.1p-idp.ihm.amazon.dev",
                UI_ENDPOINT: "https://prod.ap-southeast-1.ui.1p-idp.ihm.amazon.dev"
            }
        }
    }
}

export enum URI {
    SIGNUP = "/signup",
    GET_OTP = "/getOtp",
    VERIFY_OTP = "/verifyOtp",
    LOGIN = "/login",
    CHANGE_PASSWORD = "/changePassword",
    ERROR = "/error"
}
